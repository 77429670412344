.container-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Mengatur tinggi container ke 100% viewport height */
  }
  
  .slider {
    width: 80%; /* Mengatur lebar slider ke 80% dari container */
    max-width: 800px; /* Mengatur lebar maksimal slider */
  }
  
/* Mobile */
@media (max-width: 767px) {
    .image-slider {
      width: 100%;
      margin: 0 auto;
    }
  }
  
  /* Desktop */
  @media (min-width: 768px) {
    .image-slider {
      width: 80%;
      margin: 0 auto;
    }
  }  