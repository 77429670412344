.container-slider{
    padding-top: 74.2px;
    position: relative;
    width: 100%;
    overflow: hidden;
    .top-dekor{
        position: absolute;
        width: 46px;
        height: 27px;
        top: 0;
        left: 48.4%;
        right: 48.4%;
    }
    h3{
        margin-bottom: 0;
        color: $black;
        font-weight: 600;
        font-size: 33.6px;
        line-height: 50.4px;
        text-align: center;
    }
    h4{
        font-weight: 500;
        font-size: 22.4px;
        line-height: 33.6px;
        color: $black;
        text-align: center;
        margin: 0;
        margin-top: 14.7px;
    }
    .slick-list{
        margin: 112.7px 0 178.5px 0;
        min-width: 1490px;
        .image-slider{
            border: 14px solid #F1582E;
            overflow: hidden;
            border-radius: 87.5px 0px 129.5px;
            position: relative;
            width: 387.8px;
            height: 244px;
            img{
                background-color: white;
                width: 100%;
                height: 100%;
            }
            .gradien-text{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to bottom, transparent,#F1582E );
                color: white;
                display: flex;
                justify-content: start;
                align-items: end;
                p{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 25px;
                    color: white;
                    margin: 0 0 17.5px 12.6px;
                }
            }
            .galery{
                flex-direction: column;
                justify-content: end;
                align-items: start;
                .text-besar{
                    margin: 0 0 3.5px 12.6px;
                }
                .text-kecil{
                    font-weight: 500;
                    margin: 0 0 9.8px 12.6px;
                }
            }
        }
    }
    .slick-dots{
        position: absolute;
        bottom: -45px !important;
    }
    .slick-arrow{
        display: none !important;
    }
}

@media (max-width: 1231px) {
    .sliders {
        justify-content: center;
        display: none;
        margin-right: auto;
    }
}
